import { styled } from '@mui/material/styles';
import { Grow } from '@mui/material';

const PREFIX = 'Home';

const classes = {
    appBarSearch: `${PREFIX}-appBarSearch`,
    tabsBar: `${PREFIX}-tabsBar`,
    appBarTemplate: `${PREFIX}-appBarTemplate`,
    tabs: `${PREFIX}-tabs`,
    pagination: `${PREFIX}-pagination`,
    gridContainer: `${PREFIX}-gridContainer`,
    inputField: `${PREFIX}-inputField`,
    outputField: `${PREFIX}-outputField`,
    searchButton: `${PREFIX}-searchButton`,
    copyButton: `${PREFIX}-copyButton`,
    clearButton: `${PREFIX}-clearButton`,
    loadingButton: `${PREFIX}-loadingButton`,
    categoryTitle: `${PREFIX}-categoryTitle`,
    sectionTitle: `${PREFIX}-sectionTitle`,
    allPostsTitle: `${PREFIX}-allPostsTitle`,
    categoryDescription: `${PREFIX}-categoryDescription`,
    exampleDescription: `${PREFIX}-exampleDescription`
};

const StyledGrow = styled(Grow)((
    {
        theme
    }
) => ({
    [`& .${classes.appBarSearch}`]: {
      borderRadius: 10,
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '18px',
      background: '#202020',
      margin:'20px 0px 30px 0px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '18px',
        margin: '3px 0px 50px 0px'
      },
    },

    [`& .${classes.tabsBar}`]: {
      borderRadius: 10,
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '4px',
      paddingLeft: '18px',
      paddingRight: '18px',
      margin:'20px 0px 30px -2px',
      [theme.breakpoints.down('md')]: {
        //flexDirection: 'column',
        //padding: '12px',
        margin:'10px 0px 0px 0px',
      },
    },

    [`& .${classes.appBarTemplate}`]: {
      borderRadius: 10,
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '18px 24px 8px 24px',
      background: '#202020',
      margin: '-20px 0px 50px 0px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '18px 18px 0px 18px',
        margin: '-30px 0px 50px 0px'
      },
    },

    [`& .${classes.tabs}`]: {
      textTransform:'none',
      fontSize: 16,
      fontWeight: 18,
      maxHeight: '50px',
      minHeight: '50px',
      margin:'20px 0px 0px 0px',
      borderRadius: 10,
      [theme.breakpoints.down('md')]: {
        margin:'0px 0px 0px 0px',
        maxWidth: '130px',
        minWidth: '130px',
      },
    },

    [`& .${classes.pagination}`]: {
      borderRadius: 8,
      marginTop: '24px',
      padding: '16px',
    },

    [`& .${classes.gridContainer}`]: {
      //[theme.breakpoints.down('xs', 'xl')]: {
        //flexDirection: 'column-reverse',
      //},
    },
    
    [`& .${classes.inputField}`]: {
        textTransform: 'none',
        //height: "100%",
        fontSize: '16px',
        '& .MuiInputBase-root': {
          backgroundColor: '#272727',
        },
        '& label.Mui-focused': {
            color: 'grey',
        },
        "& .MuiSvgIcon-root": {
          color: '#9d9d9d',
        },
        "& .MuiInputLabel-root": {
          top: -5.5,
          left: -2.5,
        },
        '& .MuiOutlinedInput-root': {
          color: "#9d9d9d",
          fontSize: 18,
          '& fieldset': {
              borderColor: '#313131',
              borderRadius: '4px'
            },
        },
        [theme.breakpoints.up('md')]: {
          margin: '6px 6px 6px 6px',
        },
        [theme.breakpoints.down('md')]: {
          margin: '0px 16px 12px 0px',
        },
    },

    [`& .${classes.outputField}`]: {
      textTransform: 'none',
      fontSize: '16px',
      '& .MuiInputBase-root': {
        backgroundColor: '#272727',
      },
      '& label.Mui-focused': {
          color: 'grey',
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#313131',
            borderRadius: '4px'
          },
      },
      [theme.breakpoints.up('md')]: {
        margin: '6px 0px 6px -12px',
      },
      [theme.breakpoints.down('md')]: {
        margin: '12px 0px 0px 0px',
      },
  },

    [`& .${classes.searchButton}`]: {
        borderRadius: 16,
        textTransform: 'none',
        fontSize: '16px',
        padding:0,
        "&:hover": {
            boxShadow: "#000000",
            background: "#2F4F4F"
          },
        [theme.breakpoints.up('md')]: {
          margin: '0px 0px 0px 0px',
        },
        [theme.breakpoints.down('md')]: {
          margin: '10px 0px 10px 0px',
          maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px'
        },
      },

      [`& .${classes.copyButton}`]: {
        borderRadius: 10,
        textTransform: 'none',
        fontSize: '16px',
        padding:0,
        "&:hover": {
            boxShadow: "#000000",
            background: "#414141"
          },
        [theme.breakpoints.up('md')]: {
          margin: '0px 0px 0px 0px',
        },
        [theme.breakpoints.down('md')]: {
          margin: '0px 0px 0px 0px',
        },
      },

      [`& .${classes.clearButton}`]: {
        borderRadius: 10,
        textTransform: 'none',
        fontSize: '16px',
        padding:0,
        "&:hover": {
            boxShadow: "#000000",
            background: "#414141"
          },
        [theme.breakpoints.up('md')]: {
          margin: '0px -10px 0px 0px',
        },
        [theme.breakpoints.down('md')]: {
          margin: '-16px 0px 0px 0px',
        },
      },

      [`& .${classes.loadingButton}`]: {
        //margin:'0px 0px 0px 175px',
        [theme.breakpoints.down('md')]: {
          margin: '30px 0px 30px 0px',
        },
        [theme.breakpoints.up('md')]: {
          margin: '175px 0px 0px 0px',
        },
      },

      [`& .${classes.categoryTitle}`]: {
        fontSize: 26,
        padding: '0px 0px 16px 0px',
        color: "#fafafa",
        [theme.breakpoints.up('md')]: {
          margin: '30px 0px 0px 0px',
          fontSize: 26,
          //fontWeight: 26,
        },
        [theme.breakpoints.down('md')]: {
          margin: '30px 10px 16px 10px',
          fontSize: 24,
          fontWeight: 24,
        },
          },

      [`& .${classes.categoryDescription}`]: {
        //padding: '4px 0px 16px 10px',
        color: "#fafafa",
        margin:'-12px 0px 36px 0px',
        [theme.breakpoints.up('md')]: {
          fontSize: 18,
        },
        [theme.breakpoints.down('md')]: {
          margin: '-12px 0px 20px 0px',
          fontSize: 18,
        },
          },

      [`& .${classes.sectionTitle}`]: {
        padding: '28px 0px 36px 0px',
        color: "#fafafa",
        
          },

      [`& .${classes.exampleDescription}`]: {
        //padding: '0px 16px 16px 16px',
        color: "#fafafa",
        margin:'0px 0px 0px 8px',
          },

      [`& .${classes.allPostsTitle}`]: {
        padding: '8px 16px 16px 16px',
        color: "#fafafa",
          },
  
}));

export default StyledGrow;