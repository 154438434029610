import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppBar, Avatar, Typography, Toolbar, Button, Menu, MenuItem, Grid, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LanguageIcon from '@mui/icons-material/Language';import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { useDispatch, useSelector } from "react-redux";
import decode from 'jwt-decode';
import useMediaQuery from '@mui/material/useMediaQuery';

import logo from '../../images/genelia_logo_white_withgreenbg.png';
import logo2 from '../../images/genelia_shade_logo3.png';

import { LanguageContext } from "../languageContext";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledAppBar from "./navbarStyles";

const theme = createTheme();
const PREFIX = 'Navbar';
const classes = {
  appBar: `${PREFIX}-appBar`,
  heading: `${PREFIX}-heading`,
  image: `${PREFIX}-image`,
  toolbar: `${PREFIX}-toolbar`,
  profile: `${PREFIX}-profile`,
  logout: `${PREFIX}-logout`,
  userName: `${PREFIX}-userName`,
  brandContainer: `${PREFIX}-brandContainer`,
  buttonCreate: `${PREFIX}-buttonCreate`,
  purple: `${PREFIX}-purple`
};


const Navbar = () => {

    //const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    //const userId = user?.result?._id;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { setLang } = React.useContext(LanguageContext);
    const languageState = React.useContext(LanguageContext).lang;
    const isMobile = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true
      });

    // for menulist (options: profile or logout)
    const [anchor, setAnchor] = useState(null);

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };
        
    const closeMenu = () => {
        setAnchor(null);
    };

    const chooseFrench = () => {
        setLang("fr");
        setAnchor(null);
    }

    const chooseEnglish = () => {
        setLang("en");
        setAnchor(null);
    }

    //const logout = () => {
    //    dispatch({ type: 'LOGOUT' });

    //    history.push('/');

    //    setUser(null);
    //}

    //const goToProfile = () => {
    //    history.push(`/profile/${userId}`);
    //    setAnchor(null)
    //}

    //const goToUserList = () => {
    //    history.push(`/profile/list`);
    //}

    //useEffect(() => {
    //    const token = user?.token;

    //    if (token) {
    //        const decodedToken = decode(token);

    //        if (decodedToken.exp * 1000 < new Date().getTime()) logout(); // log out if token expired, i.e. if logging in > 1h
    //    }

    //    setUser(JSON.parse(localStorage.getItem('profile')))
    //}, [location]);
    
    return (
        <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: '#749595' }} >
      <StyledAppBar className={classes.appBar} elevation={0} position="fixed" style={{ background: '#ffffff' }}>
          {/*<Link to="/" className={classes.brandContainer}>*/}
          <Grid to="/" className={classes.brandContainer}>
              <img className={classes.image} src={logo} alt="genelia logo" height={isMobile ? "35" : "30"}></img>
              {/*<img className={classes.image} src={logo2} alt="genelia" height={isMobile ? "35" : "30"}></img>*/}
              <Typography variant={isMobile ? "h5" : "h5"} style={{color: '#202020', fontSize: 24, marginTop: '-2px'}}>genelia</Typography>
          {/*</Link>*/}
          </Grid>
          
         <Toolbar className={classes.toolbar}>
            <div>
                <Button className={classes.logout}
                sx={{ color: '#202020', borderColor: '#1c1c1c' }}
                onClick={openMenu} size="large">
                    <ArrowDropDownIcon fontSize="medium" />
                    <LanguageIcon fontSize="medium" />
                </Button>
                <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted MenuListProps={{ sx: {color:'#fafafa', backgroundColor: "#272727" }}} elevation={1}>
                    <MenuItem onClick={() => chooseEnglish()} sx={{"&:hover": {backgroundColor: "#414141"}, fontSize:16, fontWeight:16}} > English </MenuItem>
                    <MenuItem onClick={() => chooseFrench()} sx={{"&:hover": {backgroundColor: "#414141"}, fontSize:16, fontWeight:16}} > Français </MenuItem>
                </Menu>
            </div>
            {/* {user ? (
                <div>
                    <Button className={classes.logout}
                    sx={{ color: 'black', backgroundColor: 'white', borderColor: 'white' }}
                    onClick={openMenu} size="large"> <AccountCircleOutlinedIcon fontSize="large" /> </Button>
                    <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                    <Typography className={classes.userName} variant="subtitle1"> &nbsp;Logged in as: {user.result.name}&nbsp;</Typography>
                        <MenuItem onClick={goToProfile}> <AccountCircleOutlinedIcon/>&nbsp;See profile</MenuItem>
                        <MenuItem onClick={logout}> <LogoutOutlinedIcon/>&nbsp;Log out</MenuItem>
                    </Menu>
                </div>
              ) : (
                <Typography>&nbsp;</Typography>
                    //<Button className={classes.buttonCreate} component={Link} to="/auth"
                    //sx={{ color: 'white', backgroundColor: '#012223', borderColor: 'black' }}
                    //variant="contained"
                    //>Log in</Button>
            )} */}
          </Toolbar>
      </StyledAppBar>
      </Box>
      <Toolbar />
      
    </React.Fragment>
    );
};

// component={Link} to="/" to point to home or history.push('/') to return to home
// JSON.parse(localStorage.getItem('')) to retrieve something from the local storage
// JSON.parse(localStorage.getItem('profile')) is the user

export default Navbar;
