import { CALL_MODEL } from '../constants/actionTypes';

export default (state = { modelResponse : [] }, action) => {
    switch (action.type) {
        //case START_LOADING:
        //    return { ...state, isLoading: true }
        //case END_LOADING:
        //    return { ...state, isLoading: false }
        case CALL_MODEL:
            return { ...state, modelResponse: action.payload }; // or with payload.post
        default:
            return state;
    }
}