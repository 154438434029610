import { FETCH_PROFILE, FETCH_USER_LIST, START_LOADING, END_LOADING } from '../constants/actionTypes';

export default (state = { isLoading: true, profile: null, posts: [] }, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true }
        case END_LOADING:
            return { ...state, isLoading: false }
        case FETCH_PROFILE:
            return { ...state, profile: action.payload.profile, posts: action.payload.posts };
        case FETCH_USER_LIST:
            return { ...state, posts: action.payload.posts };
        default:
            return state;
    }
}