const descriptionPage_contentGeneration_fr = "Créez des e-mails pertinents en quelques clics grâce à l'IA.";
const descriptionPage_contentGeneration_en = "Create relevant emails in a few clicks using AI.";

const subDescriptionPage_contentGeneration_fr = "Générez des e-mails à partir d'informations brutes, de la façon dont vous souhaitez.";
const subDescriptionPage_contentGeneration_en = "Generate fully-written emails from raw information, the way you want.";

const goodPracticesTitle_fr = "Exemples";
const goodPracticesTitle_en = "Examples";
const goodPracticesDescription_fr = "Générez des e-mails pertinents en quelques secondes\nà partir des idées et des données brutes\nque vous souhaitez transmettre.\n\nChoisissez le ton de la voix\net la langue que vous souhaitez.";
const goodPracticesDescription_en = "Generate relevant, fully-written emails in seconds\nfrom the raw ideas and data you want to transmit.\n\nChoose your tone of voice\nand the language you want.";
//const goodPracticesTitle_fr = "Guide d'utilisation et bonnes pratiques";
//const goodPracticesTitle_en = "User guide and good practices";
// const goodPracticesDescription_fr = "Notre outil a été conçu pour générer des e-mails pertinents à partir d’idées et d’informations brutes. Pour un résultat optimal, veuillez remplir les différents champs.\n\nDans le champs Destinataire, vous pouvez entrer le nom du destinaire ou sa description.\nExemples : “Steve Jobs”, “mon équipe”, ”une entreprise dans la cosmétique”\n\nDans le champs Objet, vous pouvez indiquer l'objet de l’e-mail ou son objectif.\nExemples : ”convaincre cette entreprise d’acheter mon produit”, ”inviter la direction pour une discussion sur mes résultats”\n\nDans le champs Contenu, vous pouvez renseigner ce que vous souhaitez dire et transmettre (idées, informations, chiffres, requête...), idéalement sous forme de phrases ou de liste à puces (avec retour à la ligne).\n\nEnfin, vous pouvez choisir le ton (formel ou informel), appuyer sur le bouton pour générer un e-mail, éditer le résultat puis l’utiliser.";
// const goodPracticesDescription_en = "Our tool is designed to generate relevant emails from raw ideas and information. For best results, please fill in the various fields.\n\nIn the Recipient field, you can enter the name of the recipient or their description.\nExamples: ”Steve Jobs”, ”my team”, ”a cosmetics company”\n\nIn the Subject field, you can indicate the subject of the e-mail or its goal.\nExamples: ”to convince this company to buy my product”, ”to invite the management for a discussion on my results”\n\nIn the Content field, you can fill in what you want to tell and transmit (ideas, information, figures, request...), ideally in the form of sentences or bullet points (with line breaks).\n\nFinally, you can choose the tone (formal or casual), press the button to generate an email, edit the result and then use it.";

const templatesExamplesTitle_fr = "Exemples de modèles à copier-coller";
const templatesExamplesTitle_en = "Examples of templates to copy and paste";

const featureTitle_fr = "Fonctionnalités";
const feature1_fr = "Gagnez du temps et communiquez avec facilité en utilisant l'intelligence artificielle pour écrire vos e-mails.";
const feature2_fr = "Utilisez des modèles pour adapter l’algorithme à un cas d’usage spécifique et à votre style d’écriture.";
const feature3_fr = "Choisissez le ton de la voix pour vous adapter à différentes situations.";
const feature4_fr = "Créez des messages en anglais, français, allemand et espagnol.";
const feature5_fr = "Éditez le résultat dans la console avant de l’utiliser.";
const feature6_fr = "Exportez le résultat dans votre boîte mail en un clic.";
const featureTitle_en = "Features";
const feature1_en = "Save time and communicate with ease by using artificial intelligence to write your emails.";
const feature2_en = "Use templates to make the algorithm adapt to a specific use case and your writing style.";
const feature3_en = "Choose the tone of voice to adapt to different situations.";
const feature4_en = "Create messages in English, French, German and Spanish.";
const feature5_en = "Edit the result in the console before using it.";
const feature6_en = "Export the result to your mailbox in one click.";

const tabLabel_outreach_fr = "Écrire";
const tabLabel_outreach_en = "Write";
const tabLabel_reply_fr = "Répondre";
const tabLabel_reply_en = "Reply";
const tabLabel_template_fr = "Écrire (modèle)";
const tabLabel_template_en = "Write (template)";
const tabLabelReply_template_fr = "Répondre (modèle)";
const tabLabelReply_template_en = "Reply (template)";

const tabTitle_outreach_fr = "Écrire (par défaut)";
const tabTitle_outreach_en = "Write (by default)";
const tabTitle_reply_fr = "Répondre (par défaut)";
const tabTitle_reply_en = "Reply (by default)";
const tabTitle_template_fr = "Écrire (avec un modèle)";
const tabTitle_template_en = "Write (using a template)";
const tabTitleReply_template_fr = "Répondre (avec un modèle)";
const tabTitleReply_template_en = "Reply (using a template)";

const tabDescription_outreach_fr = "Écrire un e-mail avec l'algorithme par défaut, qui a un savoir général, mais est moins précis qu'en utilisant un modèle.";
const tabDescription_outreach_en = "Write an email with the algorithm by default, which has a general knowledge, but is less accurate than using a template.";
const tabDescription_reply_fr = "Répondre à un e-mail avec l'algorithme par défaut, qui a un savoir général, mais est moins précis qu'en utilisant un modèle.";
const tabDescription_reply_en = "Reply to an email with the algorithm by default, which has a general knowledge, but is less accurate than using a template.";
const tabDescription_template_fr = "Écrire un email en utilisant un modèle, ce qui permet à l'algorithme de s'adapter à un cas d'usage spécifique et à votre style d'écriture.";
const tabDescription_template_en = "Write an email using a template, which allows the algorithm to adapt to a specific use case and to your writing style.";
const tabDescriptionReply_template_fr = "Répondre à un email en utilisant un modèle, ce qui permet à l'algorithme de s'adapter à un cas d'usage spécifique et à votre style d'écriture.";
const tabDescriptionReply_template_en = "Reply to an email using a template, which allows the algorithm to adapt to a specific use case and to your writing style.";

const requestDesign_outreach_fr = "génère un e-mail";
const requestDesign_outreach_en = "generate an email";

const requestDesign_reply_fr = "génère une réponse à un e-mail";
const requestDesign_reply_en = "generate a reply to an email";

const requestDesign_template_fr = "énère un e-mail dans le même style avec les informations suivantes : ";
const requestDesign_template_en = "generate an email in the same style with the following information: ";
const requestDesign_templateReply_fr = "génère une réponse à un e-mail dans le même style avec les informations suivantes : ";
const requestDesign_templateReply_en = "generate a reply to an email in the same style with the following information: ";

const inputLabel_template_fr = "Collez le modèle à utiliser ici";
const inputLabel_template_en = "Paste the template to use here";

const requestDesign_recipient_fr = "Destinataire : ";
const requestDesign_recipient_en = "Recipient: ";
const inputLabel_recipient_fr = "Destinataire";
const inputLabel_recipient_en = "Recipient";

const requestDesign_subject_fr = "Objet : ";
const requestDesign_subject_en = "Subject: ";
const inputLabel_subject_fr = "Sujet";
const inputLabel_subject_en = "Subject";

const requestDesign_emailToReplyTo_fr = "E-mail auquel répondre : ";
const requestDesign_emailToReplyTo_en = "Email to reply to: ";
const inputLabel_emailToReplyTo_fr = "Collez l'e-mail auquel répondre ici";
const inputLabel_emailToReplyTo_en = "Paste the email to reply to here";

const requestDesign_content_fr = "Contenu : ";
const requestDesign_content_en = "Content: ";
const inputLabel_content_fr = "Choses à transmettre";
const inputLabel_content_en = "Things to transmit";

const requestDesign_tone_fr = "Ton : ";
const requestDesign_tone_en = "Tone: ";

const inputLabel_formalTone_fr = "Formel";
const inputLabel_formalTone_en = "Formal";
const inputLabel_casualTone_fr = "Informel";
const inputLabel_casualTone_en = "Casual";

const requestDesign_language_fr = "Langue : ";
const requestDesign_language_en = "Language: ";

const tabTitle_contentGeneration_fr = "Génération de contenu";
const tabTitle_contentGeneration_en = "Content generation";

const copiedToClipBoard_message_fr = "Copié dans le presse-papiers";
const copiedToClipBoard_message_en = "Copied to clipboard";

export const dict = {
    fr: 
    {
        tabTitle_contentGeneration: tabTitle_contentGeneration_fr,
        descriptionPage_contentGeneration: descriptionPage_contentGeneration_fr,
        subDescriptionPage_contentGeneration: subDescriptionPage_contentGeneration_fr,

        tabLabel_outreach: tabLabel_outreach_fr,
        tabLabel_reply: tabLabel_reply_fr,
        tabLabel_template: tabLabel_template_fr,
        tabLabelReply_template: tabLabelReply_template_fr,
        
        requestDesign_template: requestDesign_template_fr,
        requestDesign_templateReply: requestDesign_templateReply_fr,
        requestDesign_outreach: requestDesign_outreach_fr,
        requestDesign_reply: requestDesign_reply_fr,
        requestDesign_recipient: requestDesign_recipient_fr,
        requestDesign_subject: requestDesign_subject_fr,
        requestDesign_emailToReplyTo: requestDesign_emailToReplyTo_fr,
        requestDesign_content: requestDesign_content_fr,
        requestDesign_tone: requestDesign_tone_fr,

        inputLabel_template: inputLabel_template_fr,
        inputLabel_recipient: inputLabel_recipient_fr,
        inputLabel_subject: inputLabel_subject_fr,
        inputLabel_emailToReplyTo: inputLabel_emailToReplyTo_fr,
        inputLabel_content: inputLabel_content_fr,
        inputLabel_formalTone: inputLabel_formalTone_fr,
        inputLabel_casualTone: inputLabel_casualTone_fr,
        requestDesign_language: requestDesign_language_fr,

        tabTitle_outreach: tabTitle_outreach_fr,
        tabTitle_reply: tabTitle_reply_fr,
        tabTitle_template: tabTitle_template_fr,
        tabTitleReply_template: tabTitleReply_template_fr,

        tabDescription_outreach: tabDescription_outreach_fr,
        tabDescription_reply: tabDescription_reply_fr,
        tabDescription_template: tabDescription_template_fr,
        tabDescriptionReply_template: tabDescriptionReply_template_fr,
        
        goodPracticesTitle: goodPracticesTitle_fr,
        goodPracticesDescription: goodPracticesDescription_fr,
        
        templatesExamplesTitle: templatesExamplesTitle_fr,
        
        featureTitle: featureTitle_fr,
        feature1: feature1_fr,
        feature2: feature2_fr,
        feature3: feature3_fr,
        feature4: feature4_fr,
        feature5: feature5_fr,
        feature6: feature6_fr,

        copiedToClipBoard_message: copiedToClipBoard_message_fr,
    },
    en:
    {
        tabTitle_contentGeneration: tabTitle_contentGeneration_en,
        descriptionPage_contentGeneration: descriptionPage_contentGeneration_en,
        subDescriptionPage_contentGeneration: subDescriptionPage_contentGeneration_en,

        tabLabel_outreach: tabLabel_outreach_en,
        tabLabel_reply: tabLabel_reply_en,
        tabLabel_template: tabLabel_template_en,
        tabLabelReply_template: tabLabelReply_template_en,

        requestDesign_template: requestDesign_template_en,
        requestDesign_templateReply: requestDesign_templateReply_en,
        requestDesign_outreach: requestDesign_outreach_en,
        requestDesign_reply: requestDesign_reply_en,
        requestDesign_recipient: requestDesign_recipient_en,
        requestDesign_subject: requestDesign_subject_en,
        requestDesign_emailToReplyTo: requestDesign_emailToReplyTo_en,
        requestDesign_content: requestDesign_content_en,
        requestDesign_tone: requestDesign_tone_en,
        requestDesign_language: requestDesign_language_en,

        inputLabel_template: inputLabel_template_en,
        inputLabel_recipient: inputLabel_recipient_en,
        inputLabel_subject: inputLabel_subject_en,
        inputLabel_emailToReplyTo: inputLabel_emailToReplyTo_en,
        inputLabel_content: inputLabel_content_en,
        inputLabel_formalTone: inputLabel_formalTone_en,
        inputLabel_casualTone: inputLabel_casualTone_en,

        tabTitle_outreach: tabTitle_outreach_en,
        tabTitle_reply: tabTitle_reply_en,
        tabTitle_template: tabTitle_template_en,
        tabTitleReply_template: tabTitleReply_template_en,

        tabDescription_outreach: tabDescription_outreach_en,
        tabDescription_reply: tabDescription_reply_en,
        tabDescription_template: tabDescription_template_en,
        tabDescriptionReply_template: tabDescriptionReply_template_en,

        goodPracticesTitle: goodPracticesTitle_en,
        goodPracticesDescription: goodPracticesDescription_en,
  
        templatesExamplesTitle: templatesExamplesTitle_en,

        featureTitle: featureTitle_en,
        feature1: feature1_en,
        feature2: feature2_en,
        feature3: feature3_en,
        feature4: feature4_en,
        feature5: feature5_en,
        feature6: feature6_en,

        copiedToClipBoard_message: copiedToClipBoard_message_en,
    }
};