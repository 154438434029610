const exampleTitle_fr = "Fonctionnalités";
const example1_title_fr = "Plainte concernant un produit ou un service"
const example2_title_fr = "Répondre à une réclamation client"
const example3_title_fr = "Traiter un problème avec un client"
const example4_title_fr = "Offre d'emploi"
const example1_fr = "Objet : Plainte concernant la qualité des écouteurs\n\nBonjour,\n\nJ'ai acheté les écouteurs chez Perfect Music le lundi 11 août. Plus tard, j'ai découvert que l'écouteur gauche ne fonctionnait pas. Malheureusement, le personnel a refusé de remplacer les écouteurs ou de me rendre mon argent bien que j'aie fourni le reçu.\n\nJe suis profondément déçu de la qualité du produit et du traitement irrespectueux que j'ai reçu dans votre magasin.\n\nJ'espère que ce problème sera résolu et que je pourrai récupérer mon argent, sinon, je devrai prendre d'autres mesures.\n\nCordialement,\nJerry" ;
const example2_fr = "Cher Jerry,\n\nJe suis désolé pour l'expérience désagréable que vous avez eue dans notre magasin et je comprends votre frustration. J'ai transmis votre plainte à notre équipe de direction et nous ferons de notre mieux pour que cela ne se reproduise plus.\n\nJ'ai remboursé votre achat et vous devriez recevoir votre argent sous peu. Nous souhaitons également vous offrir une réduction de 10 % lors de votre prochain achat dans notre magasin. Veuillez utiliser ce code promo pour obtenir une réduction : [lien].\n\nVeuillez accepter nos excuses pour le désagrément que vous avez subi.\n\nMeilleures salutations,\n\nAnja\nSupport client\nsupport.client@monentreprise.com";
const example3_fr = "Cher [nom],\n\nMerci de nous avoir contactés et d'avoir signalé le problème concernant [type de problème]. Nous sommes désolés pour ce désagrément et nous vous remercions d'avoir pris contact avec nous.\n\nVotre problème concernant [sujet] est examiné par le [département] au moment où j'écris cet e-mail.\n\nLes prochaines étapes sont les suivantes : [action 1], [action 2], [action 3]. Je vous garantis que vous recevrez plus d'informations d'ici [délai].\n\nMerci de votre patience et à bientôt,\n\nRobin";
const example4_fr = "Cher [Nom du candidat],\n\nJ'ai été ravi de faire votre connaissance au cours des dernières semaines.\n\nNous avons été impressionnés par votre expérience et sommes ravis de vous proposer le poste de [Titre du poste] au sein de [Nom de l'entreprise].\nIl s'agit d'un poste à [temps plein/temps partiel] qui devrait débuter le [date].\n\nVous trouverez une lettre d'offre détaillée jointe à cet e-mail.\n\nSi vous décidez d'accepter cette offre, veuillez signer, scanner et envoyer votre lettre à [adresse électronique/nom du contact] avant le [date limite de décision].\n\nEn attendant, n'hésitez pas à me contacter si vous avez des questions. N'hésitez pas à m'envoyer un courriel ou à m'appeler directement au [numéro de téléphone].\n\nNous sommes impatients d'avoir de vos nouvelles et espérons sincèrement que vous rejoindrez notre équipe!\n\nSincères salutations,\nPierre";

const exampleTitle_en = "examples";
const example1_title_en = "Complaint about a product or service"
const example2_title_en = "Respond to a customer complaint"
const example3_title_en = "Handle a customer issue"
const example4_title_en = "Job offer"
const example1_en = "Subject: Complaint regarding the quality of the headphones\n\nHi there, \n\n I purchased the headphones at Perfect Music on Monday, August 11. Later, I discovered that the left headphone wasn’t working. Unfortunately, the staff refused to replace the headphones or return my money although I provided the receipt.\n\nI’m deeply disappointed about the quality of the product and the disrespectful treatment I received in your store.\n\nI hope to have this issue resolved and get my money back, otherwise, I will have to take further actions.\n\nBest,\nJerry";
const example2_en = "Dear Jerry,\n\nI’m sorry for the unpleasant experience you had in our store and I can understand your frustration. I have forwarded your complaint to our management team, and we’ll do our best to make sure this never happens again.\n\nI refunded your purchase, and your funds should be with you shortly. We also want to offer you a 10% discount for your next purchase in our store. Please use this promo code to get a discount: [link].\n\nPlease accept our apologies for the inconvenience you had.\n\nBest regards,\nAnja\nCustomer Support\ncustomer.support@mycompany.com";
const example3_en = "Dear [name],\n\nThank you for reaching out and signaling the problem about [issue type]. We are sorry for this inconvenience, and we appreciate you reaching out.\n\nYour issue with [subject] is being examined by the [department] as I’m writing this email.\n\nThe next steps are: [action 1], [action 2], [action 3]. I guarantee you will receive more information within [time].\n\nThank you for your patience and talk to you soon,\n\nRobin";
const example4_en = "Dear [Candidate Name],\n\nIt was wonderful getting to know you over the past few weeks. We were impressed with your experience and are delighted to offer you the position of [Job Title] with [Company Name]. This is a [Full time/Part time] role with the anticipated start date of [Date].\n\nYou’ll find a detailed offer letter attached with this email. If you choose to accept this offer, please sign, scan and send over your letter to [email address/contact name] by [decision deadline].\n\nIn the meantime, please don’t hesitate to reach out to me with any questions. Feel free to shoot me an email or call me directly at [your phone number].\n\nWe’re looking forward to hearing from you and sincerely hope that you’ll be joining our team!\n\nBest regards,\nPierre";

export const dict = {
    fr: 
    {

        exampleTitle: exampleTitle_fr,
        example1_title: example1_title_fr,
        example2_title: example2_title_fr,
        example3_title: example3_title_fr,
        example4_title: example4_title_fr,
        example1: example1_fr,
        example2: example2_fr,
        example3: example3_fr,
        example4: example4_fr,

    },
    en:
    {

        exampleTitle: exampleTitle_en,
        example1_title: example1_title_en,
        example2_title: example2_title_en,
        example3_title: example3_title_en,
        example4_title: example4_title_en,
        example1: example1_en,
        example2: example2_en,
        example3: example3_en,
        example4: example4_en,

    }
};