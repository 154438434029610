import React, { useState } from 'react';
import { Card, Paper, Typography, ButtonBase, Grid, Menu, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactTinyLink } from 'react-tiny-link';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LanguageContext } from "../languageContext";
import { dict } from './templateConstants';

//import { addPostToUserList } from '../../../actions/posts';

import { ThemeProvider, createTheme } from '@mui/material/styles';
//import StyledCard from './postStyles';

const theme = createTheme();
const PREFIX = 'templatePreview';

const classes = {
    border: `${PREFIX}-border`,
};

const TemplatePreview = ({ valueTab }) => {
    const isMobile = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true
      });
    const languageState = React.useContext(LanguageContext).lang;

    //const dispatch = useDispatch();
    //const history = useHistory();
    //const [inList, setInList] = useState(post?.inList); // to have the save button colored directly
    //const hasPostInList = inList?.find((id) => id === userId);
    //const handleAddToList = async () => {
    //    dispatch(addPostToUserList(post._id));
//
    //    if(hasPostInList) {
    //        setInList(post.inList.filter((id) => id !== userId));
    //    } else {
    //        setInList([...post.inList, userId]);
    //    }
    //};
    //const AddToList = () => {
    //    if (inList.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
    //        return inList.find((id) => id === userId)
    //            ? (
    //            <><QueueIcon fontSize="small" />&nbsp;Enregistré</>
    //        ) : (
    //            <><QueueOutlinedIcon fontSize="small" />&nbsp;Enregistrer</>
    //        );
    //    }
    //
    //    return <><QueueOutlinedIcon fontSize="small" />&nbsp;Enregistrer</>;
    //};
    //const openPost = () => history.push(`/posts/${post._id}`);


    return (
    <React.Fragment>
        { ((valueTab === 1 && !isMobile) || (valueTab === 1 && isMobile) || (valueTab === 3 && isMobile)) && (
        <Card sx={{ backgroundColor: '#272727', padding:'1.3%', maxWidth: (isMobile ? '46.5%' : '100%'), marginBottom:'18px'}}>
            <Typography sx={{ color: '#eeeeee', fontSize: 20, fontWeight: 20, marginBottom:'6px'}}>{dict[languageState].example1_title}</Typography>
            <Typography sx={{ color: '#bbbbbb', fontWeight: 20, whiteSpace: 'pre-line'}}>{dict[languageState].example1}</Typography>
        </Card>
        )}
        { ((valueTab === 3 && !isMobile) || (valueTab === 1 && isMobile) || (valueTab === 3 && isMobile)) && (
        <Card sx={{ backgroundColor: '#272727', padding:'1.3%', maxWidth: (isMobile ? '46.5%' : '100%'), marginBottom:'18px'}}>
            <Typography sx={{ color: '#eeeeee', fontSize: 20, fontWeight: 20, marginBottom:'6px'}}>{dict[languageState].example2_title}</Typography>
            <Typography sx={{ color: '#bbbbbb', fontWeight: 20, whiteSpace: 'pre-line'}}>{dict[languageState].example2}</Typography>
        </Card>
        )}

    </React.Fragment>
    );
}

//&nbsp; = space
// possibility to add like - likes if numerous likes: check tutorial 04:46:22 & github part 3
// for delete and edit features, check if the user is the creator before allowing them
// button base general button of the post/card

export default TemplatePreview;