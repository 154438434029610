import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const PREFIX = 'Input';

const classes = {
  inputField: `${PREFIX}-inputField`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
  ) => ({
    [`& .${classes.inputField}`]: {
        textTransform: 'none',
        fontSize: '16px',
        '& label.Mui-focused': {
            color: 'grey',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'grey',
              borderRadius: '12px'
            },
        },
    },
  }));

  export default StyledGrid;