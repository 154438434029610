import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

const PREFIX = 'Auth';

const classes = {
    paper: `${PREFIX}-paper`,
    root: `${PREFIX}-root`,
    avatar: `${PREFIX}-avatar`,
    form: `${PREFIX}-form`,
    submit: `${PREFIX}-submit`,
    googleButton: `${PREFIX}-googleButton`,
    swithModeButton: `${PREFIX}-swithModeButton`
};

const StyledContainer = styled(Container)((
  {
      theme
  }
) => ({
  [`& .${classes.paper}`]: {
      borderRadius: 15,
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },

  [`& .${classes.root}`]: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  [`& .${classes.submit}`]: {
      borderRadius: 40,
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      boxShadow: "#000000",
      background: "#000000"
    },
  },

  [`& .${classes.googleButton}`]: {
      borderRadius: 40,
    marginBottom: theme.spacing(2),
    fontSize: '16px',
    textTransform: 'none',
    "&:hover": {
      boxShadow: "#000000",
      background: "#000000"
    },
  },

  [`& .${classes.swithModeButton}`]: {
      textTransform: 'none',
      fontSize: '15px',
      "&:hover": {
        boxShadow: "#000000",
        background: "#F0EFEF"
      },
    },
}));

export default StyledContainer;