import React, { useState, useEffect } from "react";
export const LanguageContext = React.createContext();



export const LanguageProvider = ({ children }) => {
  
  var regionBrowerLanguage = window.navigator.userLanguage || window.navigator.language;

  const browserLang = (regionBrowerLanguage.substring(0, 2) == "fr") ? 'fr' : 'en'
  
  const [lang, setLang] = useState(browserLang);

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
};