import { FETCH_PROFILE, FETCH_USER_LIST, START_LOADING, END_LOADING } from '../constants/actionTypes';
import * as api from '../api'; //* as api means everything as api

// get a single user profile
export const getUserProfile = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING }); // here starts the loading
        const { data } = await api.fetchProfile(id);

        dispatch({ type: FETCH_PROFILE, payload: data }); //send data to reducer
        dispatch({ type: END_LOADING }); // here ends the loading
    } catch (error) {
        console.log(error);
    }  
}

// get posts in user list
export const getUserList = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        console.log(id)
        const { data: { data } } = await api.getUserList(id); // here, data: { data } or just data?
        dispatch({ type: FETCH_USER_LIST, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
    }  
}