import React from 'react';
import { Container, Grid } from '@mui/material';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import '@fontsource/inter/300.css';

import { LanguageProvider } from './components/languageContext';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import UserProfile from './components/UserProfile/UserProfile';

const App = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    
    return(
        <BrowserRouter>
        <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12}>
        <LanguageProvider>
            <Navbar/>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/profile/:id" component={UserProfile} />
                <Route path="/auth" exact component={() => (!user ? <Auth /> : <Redirect to="/posts" />)} />
            </Switch>
        </LanguageProvider>
        </Grid>
        </Grid>
        </BrowserRouter>
    )
};
// BrowserRouter: router implementation for HTML5 browser (use different url for different...)
// Switch: either show the home either show the auth
// first check if the user is logged in before showing Auth page

export default App;