import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import TemplatePreview from './templatePreview';
//import useStyles from './styles'; // unused

const TemplatesList = ({ valueTab, templates, isLoading, setCurrentId }) => {
    //const { posts, isLoading } = useSelector((state) => state.posts); // props added to Home and UserProfile so we can show the posts of the concerned state
    //const classes = useStyles(); // unused

    //if(!posts.length && !isLoading) return 'Pas de posts correspondant';
    
    return (
        <Grid item container xs={12} sm={12} md={12} justifyContent='space-between'>
            <TemplatePreview valueTab={valueTab} />
        </Grid>
        //isLoading ? <CircularProgress /> : (
            // <Grid container alignItems="stretch" spacing={3}>
            //    {templates.map((template) => (
            //        <Grid key={post._id} item xs={12} sm={12} md={12} lg={12}>
            //            <TemplatePreview template={template} setCurrentId={setCurrentId} />
            //        </Grid>
            //    ))}
            //</Grid>
        //)
    );
}
// !posts.length ? <CircularProgress /> : if 0 posts, circular progress turning - if >0, show the posts
//xs: size on mobile, sm: size on small-medium devices, md medium, lg large (12 divided by something)

export default TemplatesList;