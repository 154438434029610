import React, { useState, useEffect } from "react";
import { Grid, Paper, AppBar, TextField, Button, Typography, Tabs, Tab, Box, Menu, MenuItem, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import SendIcon from '@mui/icons-material/Send';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SettingsVoiceOutlinedIcon from '@mui/icons-material/SettingsVoiceOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import AutoFixNormalOutlinedIcon from '@mui/icons-material/AutoFixNormalOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';

import TemplatesList from '../Template/templatesList';

import { LanguageContext } from "../languageContext";
import { dict } from './homeConstants';

import gifWaitingModelResult from '../../images/brain-sticker-grey-2.gif';
import woman_email from '../../images/woman_email.png';
import example1_en from '../../images/example1_en.png';
import example1_fr from '../../images/example1_fr.png';
import example2_en from '../../images/example2_en.png';
import example2_fr from '../../images/example2_fr.png';
import example3_en from '../../images/example3_en.png';
import example3_fr from '../../images/example3_fr.png';

//import { getLanguageModel } from '../../actions/languageModel'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from "./homeStyles";

const theme = createTheme();
const PREFIX = 'Home';
const classes = {
    appBarSearch: `${PREFIX}-appBarSearch`,
    tabsBar: `${PREFIX}-tabsBar`,
    appBarTemplate: `${PREFIX}-appBarTemplate`,
    tabs: `${PREFIX}-tabs`,
    pagination: `${PREFIX}-pagination`,
    gridContainer: `${PREFIX}-gridContainer`,
    inputField: `${PREFIX}-inputField`,
    outputField: `${PREFIX}-outputField`,
    searchButton: `${PREFIX}-searchButton`,
    copyButton: `${PREFIX}-copyButton`,
    clearButton: `${PREFIX}-clearButton`,
    searchButton: `${PREFIX}-searchButton`,
    loadingButton: `${PREFIX}-loadingButton`,
    categoryTitle: `${PREFIX}-categoryTitle`,
    sectionTitle: `${PREFIX}-sectionTitle`,
    allPostsTitle: `${PREFIX}-allPostsTitle`,
    categoryDescription: `${PREFIX}-categoryDescription`,
    exampleDescription: `${PREFIX}-exampleDescription`
};

const Home = () => {
    const isMobile = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true
      });
    const languageState = React.useContext(LanguageContext).lang;
    const [valueTab, setValueTab] = useState(0);
    const [templateEmail, setTemplateEmail] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');
    const [subjectEmail, setSubjectEmail] = useState('');
    const [emailToReplyTo, setEmailToReplyTo] = useState('');
    const [contentEmail, setContentEmail] = useState('');
    const [toneEmail, setToneEmail] = useState(dict[languageState].inputLabel_formalTone);
    const [languageEmail, setLanguageEmail] = useState(languageState === "fr" ? "Français" : "English");
    const [resultLanguageModel, setResultLanguageModel] = useState('');

    function handleChange(event, newValueTab) {
        setValueTab(newValueTab);
    };

    const tones = [
        {value: dict[languageState].inputLabel_formalTone},
        {value: dict[languageState].inputLabel_casualTone}
    ];

    const languagesEmail = [
        {value: "English"},
        {value: "Français"},
        {value: "Deutsch"},
        {value: "Español"}
    ];

    useEffect(() => {
        setToneEmail(dict[languageState].inputLabel_formalTone)
        }, [languageState]);

    useEffect(() => {
        setLanguageEmail(languageState === "fr" ? "Français" : "English")
        }, [languageState]);

    //dispatch(getLanguageModel("explique moi économie avec des mots simples"))

    const [open, setOpen] = useState(false)
    const handleCopyToClipboard = () => {
          setOpen(true)
          navigator.clipboard.writeText(resultLanguageModel)
    };

    const [anchor, setAnchor] = useState(null);
    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };  
    const closeMenu = () => {
        setAnchor(null);
    };

    const [resultLanguageModelForExportMail, setResultLanguageModelForExportMail] = useState('');
    const [subjectForExportMail, setSubjectForExportMail] = useState('');
    const [sfex1, setSfex1] = useState('');
    const [sfex2, setSfex2] = useState('');
    useEffect(() => {
        if (resultLanguageModel.indexOf('Objet :') > -1)
            { setSubjectForExportMail(resultLanguageModel.split('Objet : ').pop().split('\n')[0].replace("Objet : ", "")); };
        if (resultLanguageModel.indexOf('Subject:') > -1)
            { setSubjectForExportMail(resultLanguageModel.split('Subject: ').pop().split('\n')[0].replace("Subject: ", "")); };
        setSfex1(resultLanguageModel.replace(`Subject: ${subjectForExportMail}\n`, ""));
    }, [resultLanguageModel]);
    useEffect(() => {
        //setResultLanguageModelForExportMail(resultLanguageModel.replace(`Subject: ${subjectForExportMail}\n`, "").replace(`Objet : ${subjectForExportMail}\n`, "").replaceAll("\n", "%0A"));
        if (resultLanguageModel.indexOf('Objet :') > -1)
            { setSfex2(sfex1.replace(`Objet : ${subjectForExportMail}\n\n`, "")); };
        if (resultLanguageModel.indexOf('Subject:') > -1)
            { setSfex2(sfex1.replace(`Subject: ${subjectForExportMail}\n\n`, "")); };
        if (resultLanguageModel.indexOf('Objet :') <= -1 && resultLanguageModel.indexOf('Subject:') <= -1)
            { setSfex2(sfex1.replace(`Subject: ${subjectForExportMail}\n\n`, "")); };
    }, [sfex1]);
    useEffect(() => {
        setResultLanguageModelForExportMail(sfex2.replaceAll("\n", "%0A"))
    }, [sfex2]);

    const handleSendViaGmail = () => {
        window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=&su=${subjectForExportMail}&body=${resultLanguageModelForExportMail}&bcc=`);
        setAnchor(null);
    };

    const handleSendViaMailto = () => {
        window.location.replace(`mailto:?&subject=${subjectForExportMail}&body=${resultLanguageModelForExportMail}`);
        setAnchor(null);
    }
    
    const handleClear = () => {
        setTemplateEmail('');
        setRecipientEmail('');
        setSubjectEmail('');
        setEmailToReplyTo('');
        setContentEmail('');
        setResultLanguageModel('');
    };

    //const prompt = (valueTab === 0) ? `${dict[languageState].requestDesign_outreach}\n${dict[languageState].requestDesign_recipient}${recipientEmail}\n${dict[languageState].requestDesign_subject}${subjectEmail}\n${dict[languageState].requestDesign_content}${contentEmail}\n${dict[languageState].requestDesign_language}${languageEmail}\n${dict[languageState].requestDesign_tone}${toneEmail}`
    //    : `${dict[languageState].requestDesign_reply}\n${dict[languageState].requestDesign_emailToReplyTo}${emailToReplyTo}\n${dict[languageState].requestDesign_content}${contentEmail}\n${dict[languageState].requestDesign_language}${languageEmail}\n${dict[languageState].requestDesign_tone}${toneEmail}`;

    const prompt = (() => {
        if (valueTab === 0)
            return `${dict[languageState].requestDesign_outreach}\n${dict[languageState].requestDesign_recipient}${recipientEmail}\n${dict[languageState].requestDesign_subject}${subjectEmail}\n${dict[languageState].requestDesign_content}${contentEmail}\n${dict[languageState].requestDesign_language}${languageEmail}\n${dict[languageState].requestDesign_tone}${toneEmail}`
        if (valueTab === 2)
            return `${dict[languageState].requestDesign_reply}\n${dict[languageState].requestDesign_emailToReplyTo}${emailToReplyTo}\n${dict[languageState].requestDesign_content}${contentEmail}\n${dict[languageState].requestDesign_language}${languageEmail}\n${dict[languageState].requestDesign_tone}${toneEmail}`
        if (valueTab === 1)
            return `${templateEmail}\n\n\n${dict[languageState].requestDesign_template}\n${dict[languageState].requestDesign_recipient}${recipientEmail}\n${dict[languageState].requestDesign_subject}${subjectEmail}\n${dict[languageState].requestDesign_content}${contentEmail}`
        if (valueTab === 3)
            return `${templateEmail}\n\n\n${dict[languageState].requestDesign_templateReply}\n${dict[languageState].requestDesign_emailToReplyTo}${emailToReplyTo}\n${dict[languageState].requestDesign_content}${contentEmail}`
        })();

    const tabTitle = (() => {
        if (valueTab === 0)
            return dict[languageState].tabTitle_outreach
        if (valueTab === 2)
            return dict[languageState].tabTitle_reply
        if (valueTab === 1)
            return dict[languageState].tabTitle_template
        if (valueTab === 3)
            return dict[languageState].tabTitleReply_template
        })();

    const tabDescription = (() => {
        if (valueTab === 0)
            return dict[languageState].tabDescription_outreach
        if (valueTab === 2)
            return dict[languageState].tabDescription_reply
        if (valueTab === 1)
            return dict[languageState].tabDescription_template
        if (valueTab === 3)
            return dict[languageState].tabDescriptionReply_template
        })();

    const getLanguageModelResult = () => {
        setResultLanguageModel("loading");
        fetch(`https://synth-lm-api.herokuapp.com/getModelResult/?inputModel=${prompt}`)
         .then((response) => response.json())
         .then((data) => {
            setResultLanguageModel(data);
         })
         .catch((err) => {
            console.log("Error, please try later");
         });
    };

    return (
        <StyledGrow in>
            <Grid className={classes.gridContainer} container justifyContent="center" alignItems="center" >
                
                <Grid item xs={12} sm={12} md={12} container display="flex" flexDirection="column" alignItems="center">

                <div style={{backgroundColor:'#ffffff', minWidth: '100%', margin:'0px 0px 0px 0px'}} >
                <Grid item xs={12} sm={12} md={12} display="flex" flexDirection="column" textAlign="center">
                    <Typography className={classes.categoryTitle} variant={isMobile ? "h5" : "h5"} style={{color: '#202020'}}>
                        {dict[languageState].descriptionPage_contentGeneration}
                    </Typography>
                    { isMobile && (
                    <Typography className={classes.categoryDescription} variant={isMobile ? "h5" : "body1"} style={{color: '#707070', fontWeight: 20}}>
                        {dict[languageState].subDescriptionPage_contentGeneration}
                    </Typography>
                    )}
                </Grid>
                </div>
                
                
                <Grid item container xs={11.5} sm={12} md={11.2} display="flex" flexDirection="row" alignItems="flex-start" justifyContent='center'  >
                <Grid item container xs={12} sm={12} md={1.8} display="flex" flexDirection="row" alignItems="flex-start" justifyContent='center'  >
                <AppBar className={classes.tabsBar} position="static" color="inherit" elevation={5} style={{ background: '#202020' }} >
                <Box width="100%">
                     <Tabs value={valueTab} variant="scrollable" scrollButtons="off" allowScrollButtonsMobile onChange={handleChange} orientation={isMobile ? "vertical" : "horizontal"}
                     TabIndicatorProps={{style: {color: '#ffffff', background:'#569090'}}} style={isMobile ? {margin:'0px 6px 122px 6px'} : {margin:'0px 0px 0px 0px'}}
                     sx={{"& button.Mui-selected": {background: "#272727", color: '#5e9e9e'}, "& .MuiTab-root": {color: "#9f9f9f"}}} >
                         <Tab className={classes.tabs} icon={<CreateOutlinedIcon />} iconPosition="start" label={dict[languageState].tabLabel_outreach} style={isMobile ? {margin:'20px 0px 0px 0px'} : {margin:'0px 4px 0px 0px'}}
                         onClick={handleClear}
                         />
                         <Tab className={classes.tabs} icon={<ViewInArOutlinedIcon />} iconPosition="start" label={dict[languageState].tabLabel_template} style={isMobile ? {margin:'20px 0px 0px 0px'} : {margin:'0px 4px 0px 0px'}}
                         onClick={handleClear}
                         />
                         <Tab className={classes.tabs} icon={<QuestionAnswerOutlinedIcon />} iconPosition="start" label={dict[languageState].tabLabel_reply} style={isMobile ? {margin:'20px 0px 0px 0px'} : {margin:'0px 4px 0px 0px'}}
                         onClick={handleClear}
                         />
                         <Tab className={classes.tabs} icon={<ViewInArOutlinedIcon />} iconPosition="start" label={dict[languageState].tabLabelReply_template} style={isMobile ? {margin:'20px 0px 0px 0px'} : {margin:'0px 4px 0px 0px'}}
                         onClick={handleClear}
                         />
                     </Tabs>
                </Box>
                </AppBar>
                </Grid>
                
                {/*{ valueTab !== 2  && ( */}
                <Grid item container xs={12} sm={12} md={10.2} alignItems="space-between" display="flex" flexDirection="row">
                <AppBar className={classes.appBarSearch} position="static" color="inherit" elevation={5} style={{ }} >

                <Grid style={{margin:'0px 0px 20px 0px'}}>
                    <Typography variant={isMobile ? "h5" : "h5"} style={{color: '#eeeeee', fontSize: 20, fontWeight: 20, margin:'4px 0px 5px 13px'}}>
                        {tabTitle}
                    </Typography>
                    <Typography variant={isMobile ? "body1" : "body1"} style={{color: '#bbbbbb', fontSize: 16, fontWeight: 20, margin:'0px 0px 0px 14px'}}>
                        {tabDescription}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} display="flex" flexDirection={isMobile ? "row" : "column" } marginTop={isMobile ? '-6px' : '0px'} textAlign="center">
                    <Grid item xs={0} sm={12} md={5.25}>
                        
                        { (valueTab === 1 || valueTab === 3) && (
                        <TextField label={templateEmail ? "" : dict[languageState].inputLabel_template} className={classes.inputField} InputProps={{ style: { color: '#fafafa', fontSize: 18, fontWeight: 18, padding: 10 }, maxLength: 100}}
                        InputLabelProps={{style: { color: '#858585', fontSize: 18, fontWeight: 18 }, shrink: false}}
                        name="recipient" variant="outlined" multiline minRows={2} fullWidth
                        value={templateEmail} onChange={(e) => setTemplateEmail(e.target.value)}
                        />
                        )}
                        
                        { (valueTab === 0 || valueTab === 1) && (
                        <TextField label={recipientEmail ? "" : dict[languageState].inputLabel_recipient} className={classes.inputField} InputProps={{ style: { color: '#fafafa', fontSize: 18, fontWeight: 18, padding: 10 }, maxLength: 100}}
                        InputLabelProps={{style: { color: '#858585', fontSize: 18, fontWeight: 18 }, shrink: false}}
                        name="recipient" variant="outlined" multiline minRows={1} fullWidth
                        value={recipientEmail} onChange={(e) => setRecipientEmail(e.target.value)}
                        />
                        )}

                        { (valueTab === 0 || valueTab === 1) && (
                        <TextField label={subjectEmail ? "" : dict[languageState].inputLabel_subject} className={classes.inputField} InputProps={{ style: { color: '#fafafa', fontSize: 18, fontWeight: 18, padding: 10 }, maxLength: 100}}
                        InputLabelProps={{style: { color: '#858585', fontSize: 18, fontWeight: 18 }, shrink: false}}
                        name="subject" variant="outlined" multiline minRows={1} fullWidth
                        value={subjectEmail} onChange={(e) => setSubjectEmail(e.target.value)}
                        />
                        )}

                        { (valueTab === 2 || valueTab === 3) && (
                        <TextField label={emailToReplyTo ? "" : dict[languageState].inputLabel_emailToReplyTo} className={classes.inputField} InputProps={{ style: { color: '#fafafa', fontSize: 18, fontWeight: 18, padding: 10 }, maxLength: 100}}
                        InputLabelProps={{style: { color: '#858585', fontSize: 18, fontWeight: 18 }, shrink: false}}
                        name="subject" variant="outlined" multiline minRows={3.3} fullWidth
                        value={emailToReplyTo} onChange={(e) => setEmailToReplyTo(e.target.value)}
                        />
                        )}
                        
                        {/*{ (valueTab === 0 || valueTab === 1)  && (*/}
                        <TextField label={contentEmail ? "" : dict[languageState].inputLabel_content} className={classes.inputField} InputProps={{ style: { color: '#fafafa', fontSize: 18, fontWeight: 18, padding: 10 }, maxLength: 1000}}
                        InputLabelProps={{style: { color: '#858585', fontSize: 18, fontWeight: 18 }, shrink: false}}
                        name="content" variant="outlined" multiline minRows={isMobile ? 7 : 5} fullWidth
                        //placeholder={"line 1\nline2\nline3"}
                        value={contentEmail} onChange={(e) => setContentEmail(e.target.value)}
                        />
                        {/*)}*/}

                        
                        <Grid display= 'flex' flexDirection= 'row' justifyContent= 'space-between' alignItems= 'center'>
                        <Grid>
                        { (valueTab === 0 || valueTab === 2)  && (
                        <TextField label={" "} className={classes.inputField} select InputProps={{ style: { color: '#9f9f9f', fontSize: 18, padding: 10, fontWeight: 18 }, maxLength: 100, startAdornment: (
                        <SettingsVoiceOutlinedIcon fontSize="small" style={{ color: '#9f9f9f', marginRight:'6px'}}/> )}}
                        InputLabelProps={{style: { color: '#9f9f9f', fontSize: 18, fontWeight: 18 }, shrink: false}}
                        name="tone" variant="outlined" multiline minRows={1}
                        value={toneEmail} onChange={(e) => setToneEmail(e.target.value)} 
                        SelectProps={{MenuProps: {PaperProps: {sx: {color: "#fafafa", backgroundColor: "#272727"}}, sx: {"&& .Mui-selected": {backgroundColor: "#333333"}}}}}
                        >
                        {tones.map((option) => (
                            <MenuItem key={option.value} value={option.value} sx={{"&:hover": {backgroundColor: "#414141"}, fontSize:16, fontWeight:16}}>
                                {option.value}
                            </MenuItem>
                        ))}
                        </TextField>
                        )}
                        
                        { (valueTab === 0 || valueTab === 2)  && (
                        <TextField label={" "} className={classes.inputField} select InputProps={{ style: { color: '#9f9f9f', fontSize: 18, padding: 10, fontWeight: 18}, maxLength: 100, startAdornment: (
                        <TranslateOutlinedIcon fontSize="small" style={{ color: '#9f9f9f', marginRight:'6px'}}/> )}}
                        InputLabelProps={{style: { color: '#9f9f9f', fontSize: 18, fontWeight: 18 }, shrink: false}}
                        name="language" variant="outlined" multiline minRows={1}
                        value={languageEmail} onChange={(e) => setLanguageEmail(e.target.value)} 
                        SelectProps={{MenuProps: {PaperProps: {sx: {color: "#fafafa", backgroundColor: "#272727"}}, sx: {"&& .Mui-selected": {backgroundColor: "#333333"}}}}}
                        >
                        {languagesEmail.map((option) => (
                            <MenuItem key={option.value} value={option.value} sx={{"&:hover": {backgroundColor: "#414141"}, fontSize:16, fontWeight:16}}>
                                {option.value}
                            </MenuItem>
                        ))}
                        </TextField>
                        )}
                        </Grid>

                        { (templateEmail || recipientEmail || subjectEmail || contentEmail || emailToReplyTo || resultLanguageModel) && (
                        <Button className={classes.clearButton}
                        sx={{ color: '#9d9d9d', backgroundColor: '#272727', borderColor: '#9d9d9d', maxWidth: '38px', maxHeight: '38px', minWidth: '38px', minHeight: '38px'}}
                        onClick={handleClear}
                        >
                            <ClearIcon fontSize="medium" style={{ color: '#909090' }}/>
                        </Button>
                        )}

                        </Grid>
                    </Grid>
                    
                    <Grid item xs={0} sm={12} md={1.5} alignItems="center" display="flex" justifyContent="center" flexDirection="column">
                        {/*{ (valueTab === 0 || valueTab === 1)  && (*/}
                        <Button onClick={getLanguageModelResult} className={classes.searchButton} variant="contained" //fullWidth
                        sx={{ color: 'white', backgroundColor: '#417171', borderColor: 'black', maxWidth: '88px', maxHeight: '35px', minWidth: '88px', minHeight: '35px'}}>
                            &nbsp;<SendIcon fontSize="medium" style={{ color: '#fafafa' }}/>
                        </Button>
                        {/*)}*/}
                    </Grid>

                    
                    { resultLanguageModel === "loading" && (
                        <Grid item xs={12} sm={12} md={5.25} container justifyContent="center">
                        <CircularProgress className={classes.loadingButton} style={{'color': '#fafafa'}}></CircularProgress>
                        </Grid>
                    )}
                    { resultLanguageModel !== "loading" && (isMobile || resultLanguageModel)  && (
                        <Grid item xs={0} sm={12} md={5.25} container justifyContent="center">
                        <TextField type='text' className={classes.outputField} InputProps={{ inputProps: { style: { color: '#fafafa', fontSize: 18, fontWeight: 18 }}, endAdornment:
                        <Grid>
                            <Button className={classes.copyButton} 
                            sx={{ color: 'white', backgroundColor: '#292929', borderColor: 'black', maxWidth: '32px', maxHeight: '32px', minWidth: '32px', minHeight: '32px', position: "absolute", bottom: 12, right: 48}}
                            onClick={handleCopyToClipboard}>
                                <ContentCopyIcon fontSize="medium" style={{ color: '#9f9f9f' }}/>
                            </Button>
                            <Button className={classes.copyButton} 
                            sx={{ color: 'white', backgroundColor: '#292929', borderColor: 'black', maxWidth: '32px', maxHeight: '32px', minWidth: '32px', minHeight: '32px', position: "absolute", bottom: 12, right: 12}}
                            onClick={openMenu}>
                                <ForwardToInboxOutlinedIcon fontSize="medium" style={{ color: '#9f9f9f' }}/>
                            </Button>
                            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted MenuListProps={{ sx: {color:'#fafafa', backgroundColor: "#272727", fontSize:18 }}} elevation={1}>
                                <MenuItem onClick={() => handleSendViaMailto()} sx={{"&:hover": {backgroundColor: "#414141"}, fontSize:16, fontWeight:16}} > <EmailOutlinedIcon fontSize="medium"/> &nbsp;&nbsp;Mail </MenuItem>
                                <MenuItem onClick={() => handleSendViaGmail()} sx={{"&:hover": {backgroundColor: "#414141"}, fontSize:16, fontWeight:16}} > <GoogleIcon fontSize="medium"/> &nbsp;&nbsp;Gmail </MenuItem>
                            </Menu>
                        </Grid>}}
                            variant='outlined'InputLabelProps={{style: { color: '#808080', fontSize: 18 }}} multiline minRows={(valueTab === 1 || valueTab === 3) ? 15.6 : 14.5} fullWidth
                            value={resultLanguageModel} onChange={(e) => setResultLanguageModel(e.target.value)}
                        />
                        </Grid>
                    )}
                    </Grid>

                    <Snackbar
                        open={open}
                        onClose={() => setOpen(false)}
                        autoHideDuration={2000}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        ContentProps={{sx: {display: 'block', textAlign: "center", color: '#fafafa', fontSize: 16}}}
                        message={dict[languageState].copiedToClipBoard_message}
                      />
                    
                </AppBar>

                { (valueTab === 1 || valueTab === 3)  && (   
                <Grid item container xs={12} sm={12} md={12} alignItems="space-between" display="flex" flexDirection="row">
                <AppBar className={classes.appBarTemplate} position="static" color="inherit" elevation={5} >
                    <Typography className={classes.sectionTitle} variant="h5" style={{color: '#f5f5f5', fontSize: 22, fontWeight: 22, padding: '10px 0px 24px 12px'}}>
                        {dict[languageState].templatesExamplesTitle}
                    </Typography>
                    <Grid item container xs={12} sm={12} md={12} >
                        <TemplatesList valueTab={valueTab}/>
                    </Grid>
                </AppBar>
                </Grid>
                )}
                </Grid>
                {/*)}*/}

                

                </Grid>

                
                
                <div style={{backgroundColor:'#ffffff'}}>
                <Grid item xs={12} sm={12} md={12} display="flex" flexDirection="row" alignItems="flex-start" justifyContent='center'>
                    <Divider variant="middle" style={{backgroundColor: '#f5f5f5', margin:'16px 0px 10px 0px'}}/>
                    <Grid item xs={11} sm={12} md={11} container paddingTop='10px'>
                    <Grid item xs={12} sm={12} md={12} textAlign="center">
                    <Typography className={classes.sectionTitle} variant="h5" style={{color: '#141414', fontSize: 26, fontWeight: 26}}>
                        {dict[languageState].featureTitle}
                    </Typography>
                    </Grid>
                    {/* <AppBar className={classes.appBarSearch} position="static" color="inherit" elevation={5} style={{ background: '#202020', margin:'-10px 0px 40px 0px', padding:'44px 10px 0px 10px' }} > */}
                
                    <Grid container item xs={12} sm={12} md={8} display="flex" flexDirection="row" paddingTop='10px' marginTop='0px'>
                        
                        <Grid item xs={12} sm={12} md={6} display="flex" flexDirection="row" justifyContent='flex-start' alignItems="flex-start" style={{ margin:'0px 0px 10px 0px' }}>
                            <TipsAndUpdatesOutlinedIcon fontSize="large" style={{ color: '#8875bd', marginLeft:'10px', marginRight:'14px', marginTop:'-4px' }}/>
                            <Typography className={classes.categoryDescription} variant="body1" style={{color: '#3b4444', whiteSpace: 'pre-line', fontSize: 18, fontWeight: 18, marginRight:'10px' }}>
                                {dict[languageState].feature1}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} display="flex" flexDirection="row" justifyContent='flex-start' alignItems="flex-start" style={{ margin:'0px 0px 10px 0px' }}>
                            <ViewInArOutlinedIcon fontSize="large" style={{ color: '#8875bd', marginLeft:'10px', marginRight:'14px', marginTop:'-4px' }}/>
                            <Typography className={classes.categoryDescription} variant="body1" style={{color: '#3b4444', whiteSpace: 'pre-line', fontSize: 18, fontWeight: 18, marginRight:'10px' }}>
                                {dict[languageState].feature2}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} display="flex" flexDirection="row" justifyContent='flex-start' alignItems="flex-start" style={{ margin:'0px 0px 10px 0px' }}>
                            <SettingsVoiceOutlinedIcon fontSize="large" style={{ color: '#8875bd', marginLeft:'10px', marginRight:'14px', marginTop:'-4px' }}/>
                            <Typography className={classes.categoryDescription} variant="body1" style={{color: '#3b4444', whiteSpace: 'pre-line', fontSize: 18, fontWeight: 18, marginRight:'10px' }}>
                                {dict[languageState].feature3}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} display="flex" flexDirection="row" justifyContent='flex-start' alignItems="flex-start" style={{ margin:'0px 0px 10px 0px' }}>
                            <TranslateOutlinedIcon fontSize="large" style={{ color: '#8875bd', marginLeft:'10px', marginRight:'14px', marginTop:'-4px' }}/>
                            <Typography className={classes.categoryDescription} variant="body1" style={{color: '#3b4444', whiteSpace: 'pre-line', fontSize: 18, fontWeight: 18, marginRight:'10px' }}>
                                {dict[languageState].feature4}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} display="flex" flexDirection="row" justifyContent='flex-start' alignItems="flex-start" style={{ margin:'0px 0px 10px 0px' }}>
                            <AutoFixNormalOutlinedIcon fontSize="large" style={{ color: '#8875bd', marginLeft:'10px', marginRight:'14px', marginTop:'-4px' }}/>
                            <Typography className={classes.categoryDescription} variant="body1" style={{color: '#3b4444', whiteSpace: 'pre-line', fontSize: 18, fontWeight: 18, marginRight:'10px' }}>
                                {dict[languageState].feature5}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} display="flex" flexDirection="row" justifyContent='flex-start' alignItems="flex-start" style={{ margin:'0px 0px 0px 0px' }}>
                            <ForwardToInboxOutlinedIcon fontSize="large" style={{ color: '#8875bd', marginLeft:'10px', marginRight:'14px', marginTop:'-4px' }}/>
                            <Typography className={classes.categoryDescription} variant="body1" style={{color: '#3b4444' , whiteSpace: 'pre-line', fontSize: 18, fontWeight: 18, marginRight:'10px' }}>
                                {dict[languageState].feature6}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={12} md={4} display="flex" flexDirection="row" justifyContent='center' alignItems="center" paddingLeft={isMobile ? '1.5%' : '0px'} marginTop={isMobile ? '-20px' : '10px'} marginBottom={isMobile ? '40px' : '40px'}>
                        <img src={woman_email} height={isMobile ? "330" : "300"}/>
                    </Grid>
                    {/* </AppBar> */}
                    </Grid>
                    <Divider variant="middle" style={{backgroundColor: '#f5f5f5', margin:'20px 0px 10px 0px'}}/>
                </Grid>
                </div>

                <Grid item xs={12} sm={12} md={11} display="flex" flexDirection="column" marginTop="10px">
                    <Grid item xs={12} sm={12} md={12} textAlign="center">
                        <Typography className={classes.sectionTitle} variant="h5" style={{color: '#141414', fontSize: 26, fontWeight: 26}}>
                            {dict[languageState].goodPracticesTitle}
                        </Typography>
                        <img src={languageState === "en" ? example1_en : example1_fr} alt="example 1"
                        style={{borderRadius:'8px', marginBottom:'10px'}}
                        width={ isMobile ? '95%' : '100%'}
                        />
                        <img src={languageState === "en" ? example2_en : example2_fr} alt="example 2"
                        style={{borderRadius:'8px', marginBottom:'10px'}}
                        width={ isMobile ? '95%' : '100%'}
                        />
                        {/* <img src={languageState === "en" ? example3_en : example3_fr} alt="example 3"
                        style={{borderRadius:'8px', marginBottom:'10px'}}
                        width={ isMobile ? '90%' : '100%'}
                        /> */}
                    </Grid>
                </Grid>
                
                </Grid> 
            </Grid>
    </StyledGrow>
    );
};

// component={Link} to="/" to point to home
// elevation property for nice shadow
// TextField is our search here

export default Home;