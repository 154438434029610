import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

const PREFIX = 'Navbar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  heading: `${PREFIX}-heading`,
  image: `${PREFIX}-image`,
  toolbar: `${PREFIX}-toolbar`,
  profile: `${PREFIX}-profile`,
  logout: `${PREFIX}-logout`,
  userName: `${PREFIX}-userName`,
  brandContainer: `${PREFIX}-brandContainer`,
  buttonCreate: `${PREFIX}-buttonCreate`,
  purple: `${PREFIX}-purple`
};

const StyledAppBar = styled(AppBar)((
  {
    theme
  }
) => ({
  [`&.${classes.appBar}`]: {
    //borderRadius: 15,
    //margin: '12px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0px 5% 0px 5%'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 3.5% 0px 3.5%'
    },
  },

  [`& .${classes.heading}`]: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '2em',
    fontWeight: 300,
  },

  [`& .${classes.image}`]: {
    //marginLeft: '10px',
    //marginTop: '6px',
    borderRadius: 8,
    marginRight: '8px',
  },

  [`& .${classes.toolbar}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-24px',
    //width: '400px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },

  [`& .${classes.profile}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '240px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginTop: 20,
      justifyContent: 'center',
    },
  },

  [`& .${classes.logout}`]: {
    borderRadius: 10,
    marginLeft: '20px',
    textTransform: 'none',
    fontSize: '16px',
    padding:0,
    "&:hover": {
      boxShadow: "#272727",
      background: "#e1e1e1"
    },
  },

  [`& .${classes.userName}`]: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },

  [`& .${classes.brandContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.purple}`]: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },

  [`& .${classes.buttonCreate}`]: {
    borderRadius: 40,
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      boxShadow: "#000000",
      background: "#000000"
    },
  }
}));

export default StyledAppBar;