import axios from 'axios'; 

const API = axios.create({ baseURL: 'https://synth-server.herokuapp.com/' });

// in order to make the API works, function that happens for every request
// because we have to send our token back to our backend so that the backend middleware can verify that we are actually logged-in
// - Interceptors are code blocks that you can use to preprocess or post-process HTTP calls, helping with global error handling, authentication, logging, and more
// - Axios interceptors are functions that Axios calls for every request. You can use interceptors to transform the request before Axios sends it, or transform the response before Axios returns the response to your code.
API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`; // bearer token (predominant type of access token used with OAuth 2.0. A Bearer Token is an opaque string, not intended to have any meaning to clients using it)
    }

    return req; // return the actual request so that we can make all the future requests (listed below)
});

// gpt model
export const languageModel = (prompt) => API.post('/', prompt);

// sign in and sign up
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const signinGoogleUser = (result) => API.post('/user/signinGoogleUser', result);

// get one specific post (id) from the database
export const fetchPost = (id) => API.get(`/posts/${id}`);

// get the posts from the database
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);

// search- get the posts by search from the database
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}`); //search query starts with "?" and then you specify a variable

// get the posts by category from the database
export const fetchPostsByCategory = (category) => API.get(`/posts/category?category=${category}`);

// get one specific post (id) from the database
export const fetchProfile = (id) => API.get(`/profile/${id}`);

// get user list (posts saved)
export const getUserList = (id) => API.get(`/profile/list`, id);

// store a (whole) new post in the database
export const createPost = ( newPost ) => API.post('/posts', newPost);

//update a post in the database
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);

// delete a post in the database
export const deletePost = (id) => API.delete(`/posts/${id}`);

// like a post in the database
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);

// comment a post
export const comment = (comment, id) => API.post(`/posts/${id}/commentPost`, { comment });

// delete a post in the database
export const deleteComment = (id, commentId) => API.patch(`/posts/${id}/deleteComment`, { commentId });

// like a comment in the database
export const likeComment = (id, commentId) => API.patch(`/posts/${id}/likeComment`, { commentId });

// add a post to user list in the database
export const addPostToUserList = (id) => API.patch(`/posts/${id}/addPostToUserList`);