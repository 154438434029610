import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, CircularProgress, Paper, Divider, Button, Menu, MenuItem, Grid, AppBar } from '@mui/material';

import { getUserProfile } from '../../actions/profile';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from './userProfileStyles';

const theme = createTheme();
const PREFIX = 'UserProfile';

const classes = {
  profileHead: `${PREFIX}-profileHead`,
  pagination: `${PREFIX}-pagination`,
  gridContainer: `${PREFIX}-gridContainer`,
  profilePostsTitle: `${PREFIX}-profilePostsTitle`,
};


const UserProfile = () => {
    const { profile } = useSelector((state) => state);
    const posts = profile.posts
    const isLoading = profile.isLoading
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;

    const { id } = useParams();

    // get the data and posts of a single user
    useEffect(() => {
      dispatch(getUserProfile(id));
    }, [id]); //happens whenever the id of the user changes

    if (!profile.profile) {
      return (
        <CircularProgress/>
      );
    };

    return (
      
      <StyledGrow in>
          <Grid className={classes.gridContainer} container justifyContent="space-between" alignItems="stretch" spacing={3} >
            <Grid item xs={12} sm={6} md={1}>
            </Grid>
            <Grid item xs={12} sm={6} md={8} >
              <Paper className={classes.profileHead}>
                <Typography variant="h6" elevation={1} sx={{ fontWeight: 'bold' }} >{profile.profile.name}</Typography>
              </Paper>
              <Typography className={classes.profilePostsTitle} variant="h6" elevation={1} sx={{ fontWeight: 'bold' }} >
                Last uploaded resources uploaded by {profile.profile.name}
              </Typography>
              <Posts posts={posts} isLoading={isLoading} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SideBar></SideBar>
            </Grid>
          </Grid>
      </StyledGrow>
    );

};

export default UserProfile;